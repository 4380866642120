<template>
  <div id="psychomotors" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <b-table :data="psychomotors" hoverable>
            <b-table-column
              field="#"
              label="#"
              width="40"
              numeric
              v-slot="props"
            >
              {{ psychomotors.indexOf(props.row) + 1 }}
            </b-table-column>

            <b-table-column label="Name" v-slot="props">{{
              props.row.name
            }}</b-table-column>

            <b-table-column label="Actions" width="160" v-slot="props">
              <action-buttons
                @initialize-info="initializeInfo(props.row)"
                @initialize-update="initializeUpdate(props.row)"
                @initialize-remove="initializeRemove(props.row)"
              />
            </b-table-column>
          </b-table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import PSYCHOMOTORS from '../../../graphql/psychomotor/Psychomotors.gql'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'psychomotors',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Psychomotors',
      isActive: false,
      psychomotors: [],
      id: null,
    }
  },
  apollo: {
    psychomotors: {
      query: PSYCHOMOTORS,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  methods: {
    initializeInfo(psychomotor) {
      // this.$router.push(
      //   `/school/${this.schoolId}/psychomotor_info/${psychomotor.id}`
      // )
    },
    initializeUpdate(psychomotor) {
      this.$router.push(
        `/school/${this.schoolId}/psychomotor/${psychomotor.id}`
      )
    },
    initializeRemove(psychomotor) {
      this.$buefy.notification.open({
        duration: 5000,
        message: 'Not Allowed',
        position: 'is-top',
        type: 'is-danger',
        hasIcon: true,
      })
      this.id = parseInt(psychomotor.id)
    },
    remove() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation deletePsychomotor($id: Int!) {
              deletePsychomotor(input: { id: $id }) {
                psychomotor {
                  id
                }
                errors
              }
            }
          `,
          variables: {
            id: this.id,
          },
        })
        .then((response) => {
          crudNotification(
            response.data.deletePsychomotor.errors,
            'Successfully deleted.'
          )
        })
        .catch((error) => {
          // console.error(error);
        })
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.$route.params.school_id}/psychomotor/new`,
      },
    ])
  },
}
</script>
